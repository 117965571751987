// HomeLeft.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillMedicineBox } from 'react-icons/ai';
// import { IoPeopleSharp } from 'react-icons/io5';
import { BsPeopleFill } from "react-icons/bs";
import { IoPersonAdd } from "react-icons/io5";
import { MdOutlineMessage } from "react-icons/md";
import { BsQuestionCircleFill } from "react-icons/bs";
import { RiToolsFill } from "react-icons/ri";
import { GiMedicines } from "react-icons/gi";
import { BiSolidCoupon } from "react-icons/bi";
import { TfiWrite } from "react-icons/tfi";
import { RiLogoutCircleLine } from "react-icons/ri";
import { MdOutlineDiscount } from "react-icons/md";
import { IoCreate } from "react-icons/io5";
const LeftSection = ({ selectedOption, handleOptionClick }) => {
  
    const handleLogout = () => {
      // Clear the admin token from localStorage
      localStorage.removeItem('adminToken');
      // Redirect to the home page
     
    };
  

  return (
    <div className="w-1/6 bg-gray-200 p-4">
      <h2 className="text-lg font-bold mb-4">Options</h2>
      <ul className="text-[18px]">
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/products"
            className={`${selectedOption === 'products' ? 'text-blue-800 ' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('products')}
          >
            <GiMedicines /> &nbsp; All Product
          </Link>
        </li>

        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/addproduct"
            className={`${selectedOption === 'addproducts' ? 'text-blue-800 ' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('addproducts')}
          >
            <AiFillMedicineBox /> &nbsp; Add Product
          </Link>
        </li>


        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/coupons"
            className={`${selectedOption === 'coupons' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('coupons')}
          >
            <BiSolidCoupon /> &nbsp; Coupons
          </Link>
        </li>

        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/blogs"
            className={`${selectedOption === 'blogs' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('blogs')}
          >
            <TfiWrite /> &nbsp; Blogs
          </Link>
        </li>
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/users"
            className={`${selectedOption === 'about' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('about')}
          >
            <BsPeopleFill /> &nbsp; Users
          </Link>
        </li>


        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/queries"
            className={`${selectedOption === 'queries' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('queries')}
          >
            <MdOutlineMessage /> &nbsp;Help Queries
          </Link>
        </li>

        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/vendor-requests"
            className={`${selectedOption === 'vendor-requests' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('vendor-requests')}
          >
           <IoPersonAdd/> &nbsp; Vendor Requests
          </Link>
        </li>

        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/allvendors"
            className={`${selectedOption === 'allvendors' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('allvendors')}
          >
           <IoPersonAdd/> &nbsp; All Vendors
          </Link>
        </li>

        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/faq"
            className={`${selectedOption === 'faq' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('faq')}
          >
           <BsQuestionCircleFill/> &nbsp; FAQs
          </Link>
        </li>

        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/testimonial"
            className={`${selectedOption === 'testimonial' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('testimonial')}
          >
           <RiToolsFill/> &nbsp; Testimonials
          </Link>
        </li>

        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/diffqueries"
            className={`${selectedOption === 'queries' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('diffqueries')}
          >
            <MdOutlineMessage /> &nbsp; Queries
          </Link>
        </li>
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/allsmartdeals"
            className={`${selectedOption === 'queries' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('allsmartdeals')}
          >
            <MdOutlineDiscount /> &nbsp; Smart Deals
          </Link>
        </li>
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
          <Link
            to="/admin/createsmartdeal"
            className={`${selectedOption === 'queries' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
            onClick={() => handleOptionClick('allsmartdeals')}
          >
            <IoCreate /> &nbsp; Create Deal
          </Link>
        </li>
        <li className="mb-2 duration-500 p-2 rounded-lg hover:bg-blue-500 hover:text-blue-800">
      <Link
        to="/"
        className={`${selectedOption === 'logout' ? 'text-blue-800' : 'text-gray-800'} flex items-center`}
        onClick={handleLogout}
      >
        <RiLogoutCircleLine /> &nbsp; Logout
      </Link>
    </li>

      </ul>
    </div>
  );
};

export default LeftSection;
