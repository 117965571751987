import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosClient from './AxiosClient';

const EditPopup = ({ productId, onClose }) => {
  const [product, setProduct] = useState(null);
  const [editedProduct, setEditedProduct] = useState(null);
  const [formDataToSend, setFormDataToSend] = useState(null);

  useEffect(() => {
    // Fetch product data from the API
    const fetchProduct = async () => {
      try {
        // Retrieve admin token from local storage
        const adminToken = localStorage.getItem('adminToken');
        
        // Check if admin token exists
        if (adminToken) {

          const formData = new FormData();

          // Append productId to the FormData
          formData.append('productId', productId);

          // Append pincode to the FormData
          formData.append('pincode', '201001');

          const response = await axiosClient.post(`/products/get_product/${productId}`,formData, {
            headers: {
              'Authorization': `Bearer ${adminToken}`
            }
          });
          console.log(response.data.data[0].product)
          setProduct(response.data.data[0].product);
          // Initialize editedProduct with the same data
          setEditedProduct(response.data.data[0].product);
          // Initialize formDataToSend with product data
          const formDataToSend = new FormData();
          Object.entries(response.data.data[0].product).forEach(([key, value]) => {
            formDataToSend.append(key, value);
          });
          setFormDataToSend(formDataToSend);
        } else {
          console.error('Admin token not found in local storage');
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Update formDataToSend with edited field value
    const updatedFormData = new FormData();
    Object.entries(editedProduct).forEach(([key, value]) => {
      updatedFormData.append(key, value);
    });
    setFormDataToSend(updatedFormData);
  };
  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setEditedProduct(prevState => ({
      ...prevState,
      image: file
    }));
    // Update formDataToSend with image file
    const updatedFormData = new FormData(formDataToSend);
    updatedFormData.set('image', file);
    setFormDataToSend(updatedFormData);
  };

  const handleSubmit = async () => {
    try {
      // Retrieve admin token from local storage
      const adminToken = localStorage.getItem('adminToken');
      if (!adminToken) {
        console.error('Admin token not found in local storage');
        return;
      }
  
      // Create FormData to send only the changed fields
      const updatedFormData = new FormData();
  
      // Iterate over editedProduct object
      Object.entries(editedProduct).forEach(([key, value]) => {
        // Check if the value is different from the original product
        if (product[key] !== value) {
          // Append changed field to updatedFormData
          updatedFormData.append(key, value);
        }
      });
  
      // Send updatedFormData to update the product
      const response = await axiosClient.post(`/products/edit_product/${productId}`, updatedFormData, {
        headers: {
          'Authorization': `Bearer ${adminToken}`
        }
      });
      console.log('Product updated successfully:', response.data);
  
      // Reload the screen to reflect the changes
      window.location.reload();
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };
  
  

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-white rounded-lg p-8 overflow-y-auto shadow-lg max-h-screen">
      <button className="absolute top-0 right-0 px-3 py-1 text-gray-600 hover:text-gray-800" onClick={onClose}>&times;</button>
      <h2 className="text-xl font-semibold mb-4">Edit Product</h2>
      {product && (
        <div>
          {/* Display editable product data */}
          {Object.entries(product).map(([key, value]) => (
            <div key={key} className="mb-4">
              <label className="font-semibold">{key}</label>
              {key === 'image' ? (
                <input 
                  type="file" 
                  name={key} 
                  onChange={handleImageChange} 
                  className="block w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-blue-500" 
                />
              ) : (
                <input 
                  type="text" 
                  name={key} 
                  value={editedProduct[key] || ''} // Provide an empty string if value is null or undefined
                  onChange={handleFieldChange} 
                  className="block w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-blue-500" 
                />
              )}
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-end mt-4">
        <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handleSubmit}>Save</button>
        <button className="ml-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default EditPopup;
