import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TbRuler } from 'react-icons/tb';
import axiosClient from '../components/AxiosClient';

const Coupons = () => {
  const [couponsData, setCouponsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterUsed, setFilterUsed] = useState(null);
  const [selectedCoupon, setSelectedCoupon] = useState(null); // State for selected coupon

  useEffect(() => {
    const fetchCoupons = async () => {
      setIsLoading(true);
      try {
        const adminToken = localStorage.getItem('adminToken');
        const formData = new FormData();
        formData.append('used', filterUsed !== null ? filterUsed.toString() : 'None');
        formData.append('order', 'ascending'); // Order parameter set to 'ascending'

        const response = await axiosClient.post(
          '/coupons/get_coupons_admin',
          formData,
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        console.log('fetched coupons')
console.log(response.data.data[0])
        setCouponsData(response.data.data[0]);
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
      setIsLoading(false);
    };

    fetchCoupons();
  }, [filterUsed]);

  const handleFilterUsed = (usedStatus) => {
    setFilterUsed(usedStatus);
  };

  const handleViewDetails = (coupon) => {
    setSelectedCoupon(coupon);
  };

  const handleClosePopup = () => {
    setSelectedCoupon(null);
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-between mb-4">
        <button onClick={() => handleFilterUsed('True')} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:bg-blue-600 mr-4">Used</button>
        <button onClick={() => handleFilterUsed('False')} className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md focus:outline-none focus:bg-green-600">Not Used</button>
      </div>
      <h1 className="text-3xl font-bold my-8">Coupons</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div className="flex justify-between font-semibold border-b border-gray-300 pb-2">
            <div>User Name</div>
            <div>Pharmacy Name</div>
            <div>Product Name</div>
            <div>Discount Value</div>
            <div>View</div>
          </div>
          {couponsData.map((coupon, index) => (
            <div key={index} className="border border-gray-200 p-4 my-4">
              <div className="flex justify-between items-center">
                <div>{coupon.user_name}</div>
                <div>{coupon.pharmacy_name}</div>
                <div>{coupon.product_name}</div>
                <div>{coupon.discount}</div>
                <button onClick={() => handleViewDetails(coupon)}>View</button>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Popup for displaying coupon details */}
      {selectedCoupon && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
    <div className="bg-white p-8 rounded-lg shadow-md">
      <div className="mb-4">
        <h2 className="text-xl font-semibold">Coupon Details</h2>
      </div>
      <div>
      <p>User Id: {selectedCoupon.user_id}</p>

        <p>User Name: {selectedCoupon.user_name}</p>
        <p>User Email: {selectedCoupon.user_email}</p>
        <p>User Phone: {selectedCoupon.user_phone}</p>

        <p>Pharmacy Name: {selectedCoupon.pharmacy_name}</p>
        <p>Product Name: {selectedCoupon.product_name}</p>
        <p>Product Id: {selectedCoupon.product_id}</p>

        <p>Venor Id : {selectedCoupon.vendor_id}</p>
        <p>Vendor Phone: {selectedCoupon.vendor_phone}</p>

        <p>Discounted price: {selectedCoupon.discounted_price}</p>
        <p>Discount: {selectedCoupon.discount}</p>
        <p>Mrp: {selectedCoupon.mrp}</p>
        <p>Created At: {selectedCoupon.created_at}</p>
        <p>Expiry: {selectedCoupon.expiry}</p>
        <p>Is Used: {selectedCoupon.is_used.toString()}</p>
        <p>Quantity: {selectedCoupon.quantity}</p>
        {/* Add more details here */}
      </div>
      <div className="mt-4 text-right">
        <button onClick={handleClosePopup} className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md focus:outline-none focus:bg-gray-500">Close</button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default Coupons;
