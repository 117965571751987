import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ProductFormFields from '../components/ProductFormFields'; // Import the new component
import FileInput from '../components/FileInput'; // Import the new component
import axiosClient from '../components/AxiosClient';

const AddProduct = () => {
  const [formData, setFormData] = useState({
    alcohol_interaction: '',
    benefits: [],
    side_effects: [],
    country_of_origin: '',
    description: '',
    driving_interaction: '',
    facts: [],
    how_to_use: '',
    if_miss: '',
    introduction: '',
    kidney_interaction: '',
    label: '',
    lactation_interaction: '',
    liver_interaction: '',
    manufacturer_address: '',
    manufacturers: '',
    medicine_type: '',
    mrp: '',
    name: '',
    other_drugs_interaction: '',
    packaging: '',
    packaging_type: '',
    pregnancy_interaction: '',
    prescription_required: '',
    primary_use: '',
    q_a: [],
    safety_advice: '',
    salt_composition: '',
    salt_synonyms: '',
    stock: '',
    storage: '',
    use_of: '',
    image: null,
    name1: '',
    name2: '',
    name3: '',
    name4: '',
    name5: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const adminToken = localStorage.getItem("adminToken");
      if (!adminToken) {
        console.error("Admin token not found in localStorage.");
        return;
      }

      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null) {
          if (Array.isArray(formData[key])) {
            formData[key].forEach((item) => formDataToSend.append(key, item));
          } else {
            formDataToSend.append(key, formData[key]);
          }
        }
      }

      formDataToSend.append('name1', formData.name1);
      formDataToSend.append('name2', formData.name2);
      formDataToSend.append('name3', formData.name3);
      formDataToSend.append('name4', formData.name4);
      formDataToSend.append('name5', formData.name5);

      const mrpValue = parseFloat(formData.mrp);
      if (!isNaN(mrpValue)) {
        formDataToSend.append('mrp', mrpValue);
      } else {
        console.error('MRP is not a valid float value:', formData.mrp);
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${adminToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axiosClient.post('/products/create_product', formDataToSend, config);

      console.log('Response:', response.data);

      setFormData({
        alcohol_interaction: '',
        benefits: [],
        side_effects: [],
        country_of_origin: '',
        description: '',
        driving_interaction: '',
        facts: [],
        how_to_use: '',
        if_miss: '',
        introduction: '',
        kidney_interaction: '',
        label: '',
        lactation_interaction: '',
        liver_interaction: '',
        manufacturer_address: '',
        manufacturers: '',
        medicine_type: '',
        mrp: '',
        name: '',
        name1: '',
        name2: '',
        name3: '',
        name4: '',
        name5: '',
        other_drugs_interaction: '',
        packaging: '',
        packaging_type: '',
        pregnancy_interaction: '',
        prescription_required: '',
        primary_use: '',
        q_a: [],
        safety_advice: '',
        salt_composition: '',
        salt_synonyms: '',
        stock: '',
        storage: '',
        use_of: '',
        image: null,
      });
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        console.log('Response Data:', error.response.data);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      image: file,
    }));
  };

  return (
    <div className="container mx-auto">
      <div className='flex'>
        <div className="text-2xl font-bold my-4">Add Product</div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <ProductFormFields formData={formData} handleChange={handleChange} />
        <FileInput handleFileChange={handleFileChange} />
        <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">Submit</button>
      </form>
    </div>
  );
};

export default AddProduct;
