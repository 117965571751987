import React, { useState ,useContext} from 'react';
import axios from 'axios';
import axiosClient from '../components/AxiosClient';
import { useNavigate } from 'react-router-dom';
import adminContext from "../context/admin/adminContext"
const Adminlogin = () => {
    const [adminMobile, setAdminMobile] = useState('');
    const [adminOTP, setAdminOTP] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const {setExpiredToken}=useContext(adminContext);
    const handleAdminSubmit = async (e) => {
        e.preventDefault();
        await handleSubmit('admin', adminMobile, adminOTP);
    };

    const handleSubmit = async (loginType, mobile, otp) => {
        setIsLoading(true);
        try {
            // Create FormData object to send data
            const formDataToSend = new FormData();
            formDataToSend.append('mobile', mobile);
            formDataToSend.append('otp', otp);

            // Define the API endpoint based on the selected login type
            const apiUrl = '/auth/admin_login';

            const response = await axiosClient.post(apiUrl, formDataToSend);
            console.log('Login successful:', response.data.data[0].access_token);

            // Store admin token in local storage
            if (loginType === 'admin') {
                localStorage.setItem('adminToken', response.data.data[0].access_token);
            }

            // Redirect to the appropriate page
            // setExpiredToken(false);
            setTimeout(()=>{
                navigate('/admin');
            },1000);
        } catch (error) {
            console.error('Error logging in:', error);
        }
        setIsLoading(false);
    };

    return (
        <div className=''>
            <div className="flex my-10 justify-center items-center h-screen">
            <div>
                {/* Admin Login Form */}
                <form
                    onSubmit={handleAdminSubmit}
                    className="bg-gray-100 p-8 rounded-lg shadow-md mb-4"
                >
                    <h2 className="text-2xl font-semibold mb-4">Admin Login</h2>
                    <div className="mb-4">
                        <label htmlFor="adminMobile" className="block mb-2">Mobile Number</label>
                        <input
                            type="text"
                            id="adminMobile"
                            value={adminMobile}
                            onChange={(e) => setAdminMobile(e.target.value)}
                            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="adminOTP" className="block mb-2">OTP</label>
                        <input
                            type="text"
                            id="adminOTP"
                            value={adminOTP}
                            onChange={(e) => setAdminOTP(e.target.value)}
                            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Logging in...' : 'Admin Login'}
                    </button>
                </form>
            </div>

        </div>

    
        </div>
    );
};

export default Adminlogin;
