import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link for navigation
import EditPopup from '../components/EditPopup'; // Import the edit popup component
import axiosClient from '../components/AxiosClient';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null); // State to store the selected product ID
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control the visibility of the popup
  const [currentPage, setCurrentPage] = useState(1); // State to manage the current page
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [hasMore, setHasMore] = useState(true); // State to manage if there are more products to load

  const pageSize = 10; // Number of products per page

  const fetchProducts = async (page) => {
    try {
      setLoading(true);
      // Retrieve admin token from local storage
      const adminToken = localStorage.getItem('adminToken');
      
      // Check if admin token exists
      if (adminToken) {
        const response = await axiosClient.post(`/products/get_all_products/${page}/${pageSize}`, null, {
          headers: {
            'Authorization': `Bearer ${adminToken}`
          }
        });

        const newProducts = response.data.data[0];
        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
        setHasMore(newProducts.length > 0); // Set hasMore to false if no new products are returned
      } else {
        console.error('Admin token not found in local storage');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  // Function to handle edit button click
  const handleEditClick = (productId) => {
    setSelectedProductId(productId);
    setIsPopupOpen(true);
  };

  // Function to handle delete button click
  const handleDeleteClick = async (productId) => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (!adminToken) {
        console.error('Admin token not found in local storage');
        return;
      }

      await axiosClient.post(`/products/delete_product/${productId}`, null, {
        headers: {
          'Authorization': `Bearer ${adminToken}`
        }
      });

      // Refresh the products list after successful deletion
      setProducts(products.filter(product => product.id !== productId));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  // Function to handle loading more products
  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <div>
      <h2>Products</h2>
      <div className="grid grid-cols-5 gap-4">
        <div className="p-4">Image</div>
        <div className="p-4">Name</div>
        <div className="p-4">MRP</div>
        <div className="p-4">Edit</div>
        <div className="p-4">Delete</div>
        {products.map(product => (
          <React.Fragment key={product.id}>
            <div className="p-4">
              <img src={product.image ? `data:image/jpeg;base64,${product.image.image_base64}` : 'placeholder.jpg'} alt="Product" className="w-full" />
            </div>
            <div className="p-4">{product.name}</div>
            <div className="p-4">MRP: {product.mrp}</div>
            <div className="p-4">
              <button onClick={() => handleEditClick(product.id)}>Edit</button>
            </div>
            <div className="p-4">
              <button onClick={() => handleDeleteClick(product.id)}>Delete</button>
            </div>
          </React.Fragment>
        ))}
      </div>
      <hr className="my-4" /> {/* Horizontal line */}
      
      {/* Render edit popup if isPopupOpen is true */}
      {isPopupOpen && (
        <EditPopup productId={selectedProductId} onClose={() => setIsPopupOpen(false)} />
      )}
      
      {/* Load More button */}
      <div className="flex justify-center my-4">
        {loading && <span>Loading...</span>}
        {!loading && hasMore && (
          <button onClick={handleLoadMore} className="px-4 py-2 border rounded">
            Load More
          </button>
        )}
        {!hasMore && (
          <span>No more products</span>
        )}
      </div>
    </div>
  );
};

export default Products;
