import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosClient from '../components/AxiosClient';

const Popup = ({ queryId, adminToken, onClose }) => {
  const [queryDetails, setQueryDetails] = useState(null);

  useEffect(() => {
    const fetchQueryDetails = async () => {
      try {
        const response = await axiosClient.post(`/help/get_help_request/${queryId}`, null,{
          headers: {
            Authorization: `Bearer ${adminToken}`
          }
        });
        setQueryDetails(response.data);
      } catch (error) {
        console.error('Error fetching query details:', error);
      }
    };

    fetchQueryDetails();
  }, [queryId, adminToken]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-8 rounded-md shadow-md">
        {/* Display query details */}
        {queryDetails ? (
          <div>
            <h2 className="text-lg font-semibold mb-4">Query Details</h2>
            <p><strong>ID:</strong> {queryDetails.id}</p>
            <p><strong>Email:</strong> {queryDetails.email}</p>
            <p><strong>Sender ID:</strong> {queryDetails.sender_id}</p>
            <p><strong>Query:</strong> {queryDetails.query}</p>
            <p><strong>Resolved:</strong> {queryDetails.resolve.status ? 'Yes' : 'No'}</p>
            <p><strong>Created On:</strong> {queryDetails.created_on}</p>
          </div>
        ) : (
          <p>Loading query details...</p>
        )}
        <button className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

const Queries = () => {
  const [queries, setQueries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const adminToken = localStorage.getItem('adminToken');

  useEffect(() => {
    const fetchQueries = async () => {
      try {
        const response = await axiosClient.post('/help/get_all_help_requests/1/10', null,{
          headers: {
            Authorization: `Bearer ${adminToken}`
          }
        });
        console.log('All queries fetched')
        setQueries(response.data?.data?.[0] || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching queries:', error);
        setIsLoading(false);
      }
    };

    fetchQueries();
  }, [adminToken]);

  // Function to handle opening popup
  const handleViewDetails = (queryId) => {
    setSelectedQuery(queryId);
  };

  // Function to handle closing popup
  const handleClosePopup = () => {
    setSelectedQuery(null);
  };

  return (
    <div className="py-8">
      {/* Display queries */}
      {isLoading ? (
        <p>Loading queries...</p>
      ) : queries.length > 0 ? (
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 p-2">Sno.</th>
              <th className="border border-gray-300 p-2">Query Created On</th>
              <th className="border border-gray-300 p-2">Email</th>
              <th className="border border-gray-300 p-2">View Details</th>
            </tr>
          </thead>
          <tbody>
            {queries.map((query, index) => (
              <tr key={query.id} className="hover:bg-gray-100">
                <td className="border border-gray-300 p-2">{index + 1}</td>
                <td className="border border-gray-300 p-2">{query.created_on}</td>
                <td className="border border-gray-300 p-2">{query.email}</td>
                <td className="border border-gray-300 p-2">
                  <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={() => handleViewDetails(query.id)}>View Details</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No queries found.</p>
      )}
      
      {/* Popup for query details */}
      {selectedQuery && <Popup queryId={selectedQuery} adminToken={adminToken} onClose={handleClosePopup} />}
    </div>
  );
};

export default Queries;
