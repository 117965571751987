import React, { useState, useEffect, useCallback } from "react";
import axiosClient from '../components/AxiosClient';

const Allsmartdeals = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [deals, setDeals] = useState([]);
  const [id, setId] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleApproval = useCallback(async (deal_id, status) => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (adminToken) {
        const headers = {
          'Authorization': `Bearer ${adminToken}`,
        };

        let formData = new FormData();
        formData.append('deal_id', deal_id);
        formData.append('status_', status);
        headers['Content-Type'] = 'multipart/form-data';

        const response = await axiosClient.post(`smartdeals/update_deal_status`, formData, { headers });

        if (response.data && response.data.deals) {
          setDeals(response.data.deals);
        } else {
          console.error('Invalid response structure:', response.data);
        }
      } else {
        console.error('Admin token not found in local storage');
      }
    } catch (error) {
      console.error('Error updating deal status:', error);
    }
  }, []);

  const handleId = (event) => {
    setId(event.target.value);
  };

  const fetchDeals = useCallback(async () => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (adminToken) {
        const headers = {
          'Authorization': `Bearer ${adminToken}`,
        };

        let formData = null;

        if (selectedValue || id) {
          formData = new FormData();
          if (selectedValue) {
            formData.append('status_', selectedValue);
          }
          if (id) {
            formData.append('vendor_id', id);
          }
          headers['Content-Type'] = 'multipart/form-data';
        }

        const response = await axiosClient.post(`smartdeals/get_all_deals`, formData, { headers });

        if (response.data && response.data.deals) {
          setDeals(response.data.deals);
        } else {
          console.error('Invalid response structure:', response.data);
        }
      } else {
        console.error('Admin token not found in local storage');
      }
    } catch (error) {
      console.error('Error fetching deals:', error);
    }
  }, [selectedValue, id]);

  useEffect(() => {
    fetchDeals();
  }, [selectedValue,id]);

  return (
    <>
      <div>Smart Deals</div>
      <div>
        <label htmlFor="status-dropdown">Select Status: </label>
        <select id="status-dropdown" value={selectedValue} onChange={handleChange}>
          <option value="">All</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
          <option value="pending">Pending</option>
        </select>
      </div>
      <div>
        Enter Vendor id (Leave empty if you want to view all smart deals)
        <input type="text" placeholder="Enter the vendor id" onChange={handleId}></input> 
      </div>
      <div>
        {deals.map(deal => (
          <div key={deal.deal_id} className="bg-slate-200">
            <div className="p-4">Product Id: {deal.product_id}</div>
            <div className="p-4">Vendor Id: {deal.vendor_id}</div>
            <div className="p-4">Discount: {deal.discount_percentage}</div>
            <div className="p-4">Start Date: {new Date(deal.start_date).toLocaleDateString()}</div>
            <div className="p-4">End Date: {new Date(deal.end_date).toLocaleDateString()}</div>
            <div className="p-4">Status: {deal.status}</div>
            <div className="p-4">Created at: {new Date(deal.created_at).toLocaleDateString()}</div>
            {deal.status === "pending" &&
              <div>
                <button className="bg-red-200" onClick={() => handleApproval(deal.deal_id, "approved")}>Approve</button>
                <button className="bg-red-300" onClick={() => handleApproval(deal.deal_id, "rejected")}>Reject</button>
              </div>
            }
          </div>
        ))}
      </div>
    </>
  );
};

export default Allsmartdeals;
