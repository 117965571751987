import React, { useState } from 'react';
import { GoBellFill } from 'react-icons/go';
import LeftSection from './HomeLeft';
import { Route, Routes, useNavigate } from 'react-router-dom'; // Import Routes and useNavigate


const Home = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    navigate(`/admin/${option}`);
  };

  return (
    <div className="flex">
      {/* Left Section */}
      <LeftSection
      className="w-1/6 fixed top-0 left-0 h-full overflow-y-auto bg-gray-200"
      selectedOption={selectedOption} handleOptionClick={handleOptionClick} />

      {/* Right Section */}
      <div className="w-5/6 ml-1/6">
        <div className="h-14 bg-gray-100 flex justify-end items-center pr-4 text-4xl">
          <GoBellFill />
        </div>
        {/* Render the selected option */}
        <div className="p-4">
          {/* Use Routes here instead of Route */}
          <Routes>
            {children} {/* Render the child Routes */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Home;
