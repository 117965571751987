import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Adminlogin from './pages/Adminlogin';
import Home from './pages/Home';
import Blogs from './pages/Blogs';
import Products from './pages/Products';
import Users from './pages/Users';
import Queries from './pages/Queries';
import AddProduct from './pages/AddProduct';
import Coupons from './pages/Coupons';
import VendorRequests from './pages/VendorRequests';
import FAQ from './pages/FAQ';
import Testimonials from './pages/Testimonials';
import DiffQueries from './pages/DiffQueries';
import AllVendors from './pages/AllVendors';
import TokenExpiryChecker from './components/TokenExxpiry';
import Allsmartdeals from './pages/ApproveSmartDeals';
import Createsmartdeal from './components/CreateSmartDeal';
import AdminState from './context/admin/AdminState';

function App() {
  return (
    <Router>
      <AdminState>
      <TokenExpiryChecker />
      <Routes>
        <Route exact path="/" element={<Adminlogin />} />
        <Route path="/admin/*" element={<AdminProtectedRoutes />} />
      </Routes>
      </AdminState>
    </Router>
  );
}

function AdminProtectedRoutes() {
  return (
    <Home>
      <Route path="/products" element={<Products />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/vendor-requests" element={<VendorRequests />} />
      <Route path="/users" element={<Users />} />
      <Route path="/queries" element={<Queries />} />
      <Route path="/addproduct" element={<AddProduct />} />
      <Route path="/coupons" element={<Coupons />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/testimonial" element={<Testimonials />} />
      <Route path="/diffqueries" element={<DiffQueries />} />
      <Route path="/allvendors" element={<AllVendors />} />
      <Route path="/allsmartdeals" element={<Allsmartdeals/>}></Route>
      <Route path='/createsmartdeal' element={<Createsmartdeal/>}></Route>
    </Home>
  );
}

export default App;
