import React, { useState } from "react";
import axiosClient from '../components/AxiosClient';

const Createsmartdeal = () => {
  const [productId, setProductId] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Retrieve vendor token from local storage
      const adminToken = localStorage.getItem("adminToken");

      // Check if vendor token exists
      if (adminToken) {
        // Create a FormData object and append form values
        const formData = new FormData();
        formData.append('product_id', productId);
        formData.append('discount_percentage', discountPercentage);
        formData.append('start_date', new Date(startDate).toISOString());
        formData.append('end_date', new Date(endDate).toISOString());

        const response = await axiosClient.post(
          "smartdeals/create_deal_admin",
          formData,
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
              'Content-Type': 'multipart/form-data'
            },
          }
        );
        setMessage("Deal created successfully!");
      } else {
        console.error("Vendor token not found in local storage");
        setMessage("Vendor token not found. Please log in.");
      }
    } catch (error) {
      console.error("Error creating deal:", error);
      setMessage("Error creating deal: " + error.message);
    }
  };

  return (
    <div>
      <h1>Smart Deals</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Product ID:
          <input
            type="text"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
            required
          />
        </label>
        <br />
        <label>
          Discount Percentage:
          <input
            type="number"
            value={discountPercentage}
            onChange={(e) => setDiscountPercentage(e.target.value)}
            required
          />
        </label>
        <br />
        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
        </label>
        <br />
        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </label>
        <br />
        <button type="submit">Create Deal</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Createsmartdeal;
