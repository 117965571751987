import React, { useEffect, useState } from 'react';
import axiosClient from '../components/AxiosClient';
import CreateTestimonial from '../components/CreateTestimonial';
import DeleteTestimonial from '../components/DeleteTestimonial';
import EditTestimonial from '../components/EditTestimonial';
import ViewTestimonial from '../components/ViewTestimonial'; // Import the new component

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isViewPopupOpen, setIsViewPopupOpen] = useState(false); // State for viewing full response
  const [testimonialToDelete, setTestimonialToDelete] = useState(null);
  const [testimonialToEdit, setTestimonialToEdit] = useState(null);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null); // State for selected testimonial

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axiosClient.post('/testimonial/get_all_testimonial');
        console.log('Testimonial API response:', response.data.data);
        setTestimonials(response.data.data[0]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
        setError('Failed to fetch testimonials');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeleteTestimonial = (testimonialId) => {
    setIsDeletePopupOpen(true);
    setTestimonialToDelete(testimonialId);
  };

  const handleDeleteConfirmed = () => {
    window.location.reload(); // Reload the page after deletion
  };

  const handleEditTestimonial = (testimonial) => {
    setIsEditPopupOpen(true);
    setTestimonialToEdit(testimonial);
  };

  const handleEditConfirmed = (updatedTestimonial) => {
    const updatedTestimonials = testimonials.map((testimonial) =>
      testimonial._id === updatedTestimonial._id ? updatedTestimonial : testimonial
    );
    setTestimonials(updatedTestimonials);
  };

  const handleCreateTestimonial = (newTestimonial) => {
    setTestimonials([...testimonials, newTestimonial]);
  };

  const handleViewTestimonial = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setIsViewPopupOpen(true);
  };

  return (
    <div className="p-5">
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-3xl">Testimonials</h1>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => setIsCreatePopupOpen(true)}
        >
          Add Testimonial
        </button>
      </div>
      {loading ? (
        <p>Loading testimonials...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : testimonials.length > 0 ? (
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b w-1/4 text-center">User</th>
              <th className="py-2 px-4 border-b w-1/4 text-center">ID</th>
              <th className="py-2 px-4 border-b w-1/2 text-center">Response</th>
              <th className="py-2 px-4 border-b w-1/4 text-center">Link</th>
              <th className="py-2 px-4 border-b w-1/8 text-center">Edit</th>
              <th className="py-2 px-4 border-b w-1/8 text-center">Delete</th>
              <th className="py-2 px-4 border-b w-1/8 text-center">View</th> {/* Added View column */}
            </tr>
          </thead>
          <tbody>
            {testimonials.map((testimonial, index) => (
              <tr key={testimonial._id} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b text-center">{testimonial.user}</td>
                <td className="py-2 px-4 border-b text-center">{testimonial._id}</td>
                <td className="py-2 px-4 border-b text-center">
                  {testimonial.response.split(' ').slice(0, 10).join(' ')}{testimonial.response.split(' ').length > 10 && '...'}
                </td>
                <td className="py-2 px-4 border-b text-center">{testimonial.link}</td>
                <td className="py-2 px-4 border-b text-center">
                  <button
                    className="bg-green-500 text-white py-1 px-4 rounded"
                    onClick={() => handleEditTestimonial(testimonial)}
                  >
                    Edit
                  </button>
                </td>
                <td className="py-2 px-4 border-b text-center">
                  <button
                    className="bg-red-500 text-white py-1 px-4 rounded"
                    onClick={() => handleDeleteTestimonial(testimonial._id)}
                  >
                    Delete
                  </button>
                </td>
                <td className="py-2 px-4 border-b text-center">
                  <button
                    className="bg-blue-500 text-white py-1 px-4 rounded"
                    onClick={() => handleViewTestimonial(testimonial)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No testimonials found.</p>
      )}

      {isCreatePopupOpen && (
        <CreateTestimonial
          onClose={() => setIsCreatePopupOpen(false)}
          onCreate={handleCreateTestimonial}
        />
      )}

      {isDeletePopupOpen && (
        <DeleteTestimonial
          testimonialId={testimonialToDelete}
          onClose={() => setIsDeletePopupOpen(false)}
          onDelete={handleDeleteConfirmed}
        />
      )}

      {isEditPopupOpen && (
        <EditTestimonial
          testimonial={testimonialToEdit}
          onClose={() => setIsEditPopupOpen(false)}
          onEdit={handleEditConfirmed}
        />
      )}

      {isViewPopupOpen && (
        <ViewTestimonial
          testimonial={selectedTestimonial}
          onClose={() => setIsViewPopupOpen(false)}
        />
      )}
    </div>
  );
};

export default Testimonials;
