import React, { useState } from 'react';
import axios from 'axios';

const AddFAQ = ({ onClose }) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const token = localStorage.getItem('adminToken');
      const formData = new FormData();
      formData.append('question', question);
      formData.append('answer', answer);
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await axios.post(
        'https://backendvaani.onrender.com/faq/create_faq',
        formData,
        config
      );
  
      console.log('Add FAQ response:', response.data);
  
      if (response.data.code === 200) {
        // Close the popup
        onClose();
  
        // Refresh the page
        window.location.reload();
      } else {
        console.error('Error adding FAQ:', response.data.message);
      }
    } catch (error) {
      console.error('Error adding FAQ:', error);
    }
  };
  
  
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-96">
        <h2 className="text-2xl mb-4">Add FAQ</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="question" className="block text-sm font-medium text-gray-700">
              Question
            </label>
            <textarea
              id="question"
              className="mt-1 p-2 w-full border rounded-md"
              rows="3"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label htmlFor="answer" className="block text-sm font-medium text-gray-700">
              Answer
            </label>
            <textarea
              id="answer"
              className="mt-1 p-2 w-full border rounded-md"
              rows="3"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Add FAQ
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddFAQ;
