import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosClient from '../components/AxiosClient';

const Users = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminToken = localStorage.getItem('adminToken');
        if (!adminToken) {
          console.error('Admin token not found.');
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${adminToken}`
          }
        };

        const response = await axiosClient.post('/users/get_users_details/1/10', null,config);
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Users</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        userData && (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left px-4 py-2">SNo</th>
                  <th className="text-left px-4 py-2">Name</th>
                  <th className="text-left px-4 py-2">Mobile</th>
                  <th className="text-left px-4 py-2">Email</th>
                  <th className="text-left px-4 py-2">Delete</th>
                </tr>
              </thead>
              <tbody>
                {userData.data.map((userGroup, index) => (
                  <React.Fragment key={index}>
                    {userGroup.map(user => (
                      <tr key={user.id}>
                        <td className="px-4 py-2">{index * 10 + userGroup.indexOf(user) + 1}</td>
                        <td className="px-4 py-2">{user.name}</td>
                        <td className="px-4 py-2">{user.mobile}</td>
                        <td className="px-4 py-2">{user.email}</td>
                        <td className="px-4 py-2">Delete</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )
      )}
    </div>
  );
};

export default Users;
