import React, { useEffect, useState } from 'react';
import axiosClient from '../components/AxiosClient'; // Import Axios directly

const DiffQueries = () => {
  const [data, setData] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState('Cancer'); // State to track the selected query type
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [error, setError] = useState(null); // State to manage error state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem('adminToken'); // Get the token from local storage
        if (!token) {
          console.error('Token not found in localStorage');
          setLoading(false);
          return;
        }

        const config = {
          headers: {
            'Authorization': `Bearer ${token}`, // Send the token in the headers
          },
        };

        const formData = new FormData();
        formData.append('domains', selectedDomain); // Append the selected domain

        const response = await axiosClient.post('/query/get_all_queries', formData, config);

        if (response) { // Assuming the API response contains a 'code' field
          setData(response.data.queries);
        } else {
          setError(response.data.message || 'Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('No data found');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDomain]); // Re-fetch data when selectedDomain changes

  const handleDeleteQuery = async (queryId) => {
    try {
      const token = localStorage.getItem('adminToken'); // Get the token from local storage
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`, // Send the token in the headers
        },
      };

      const response = await axiosClient.post(`/query/delete_query/${queryId}`, null, config);
      console.log('Delete API response:', response.data);

      // Update data after deletion (assuming successful deletion)
      if (response) {
        const updatedQueries = data.filter(query => query.query_id !== queryId);
        setData(updatedQueries);
      } else {
        console.error('Error deleting query:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting query:', error);
    }
  };

  return (
    <div className="p-5">
      <h1 className="text-3xl mb-5">Queries</h1>
      <div className="mb-5">
        <label htmlFor="queryType" className="mr-3">Select Query Type:</label>
        <select
          id="queryType"
          value={selectedDomain}
          onChange={(e) => setSelectedDomain(e.target.value)}
          className="border rounded px-3 py-2"
        >
          <option value="Cancer">Cancer</option>
          <option value="Chronic Care">Chronic Care</option>
          <option value="Generics">Generics</option>
          <option value="Special Needs">Special Needs</option>
        </select>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : data && data.length > 0 ? (
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b w-1/6 text-center">Query ID</th>
              <th className="py-2 px-4 border-b w-1/6 text-center">Name</th>
              <th className="py-2 px-4 border-b w-1/6 text-center">Mobile</th>
              <th className="py-2 px-4 border-b w-1/6 text-center">Medicine Type</th>
              <th className="py-2 px-4 border-b w-1/6 text-center">Quantity</th>
              <th className="py-2 px-4 border-b w-1/6 text-center">Domain</th>
              <th className="py-2 px-4 border-b w-1/6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((query, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b text-center">{query.query_id}</td>
                <td className="py-2 px-4 border-b text-center">{query.name}</td>
                <td className="py-2 px-4 border-b text-center">{query.mobile}</td>
                <td className="py-2 px-4 border-b text-center">{query.medicine_type}</td>
                <td className="py-2 px-4 border-b text-center">{query.quantity}</td>
                <td className="py-2 px-4 border-b text-center">{query.domain}</td>
                <td className="py-2 px-4 border-b text-center">
                  <button
                    className="bg-red-500 text-white py-1 px-4 rounded"
                    onClick={() => handleDeleteQuery(query.query_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No queries found.</p>
      )}
    </div>
  );
};

export default DiffQueries;
